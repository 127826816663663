import "./HomeScreen3.css";
import globalStates from '../../utils/global';
import { useContext, useEffect, useMemo, useState } from "react";
import { iconHomePowder, iconHomeSeed, iconHomeWhole } from "../../assets";
import { useNavigate } from "react-router-dom";




const HomeScreen3 = () =>{
    const context = globalStates && globalStates.globalContext;
    const globalContext:any = useContext(context);
    const windowWidthClass = globalContext && globalContext.windowWidthClass;
    const defaultLanguage = globalContext && globalContext.defaultLanguage;
    

    const navigate = useNavigate()
    // data
    const deckServices = [
        {
            imgSrc: iconHomeWhole,
            name: "Rempah Utuh (Whole)"
        },
        {
            imgSrc: iconHomePowder,
            name: "Rempah Bubuk (Powder)"
        },
        {
            imgSrc: iconHomeSeed,
            name: "Rempah Biji-bijian (Seeds)"
        }
    ]

    const companyProductsEn = useMemo(() => {
        return {
            descTitle: "Our Products",
            descDetails: "We always optimize our services for all our clients from quality control, safety packaging stages, also eco-friendly."
        };
    }, []);

    const companyProductsIna = useMemo(() => ({
        descTitle: "Produk Kami",
        descDetails: "Kami selalu mengoptimalkan layanan kami untuk semua klien kami mulai dari tahap kontrol kualitas, tahap pengemasan yang aman, juga ramah lingkungan."
    }), []);

    const [companyProducts, setCompanyProducts] = useState(companyProductsEn);
    useEffect(()=>{
        if(defaultLanguage==="en"){
            setCompanyProducts(companyProductsEn)
        } else {
            setCompanyProducts(companyProductsIna)
        }
    }, [defaultLanguage, companyProductsEn, companyProductsIna]);

    const {descDetails, descTitle} = companyProducts;
    // console.log('>>>>home screen 3 default language', defaultLanguage);
    return(
        <div className={`${windowWidthClass}-home-screen-3-services`}>
            <div className="screen-wrapper">
                <div className="wrapper-desc">
                    <h3>
                        {descTitle}
                    </h3>
                    <p>
                        {descDetails}
                    </p>
                </div>
                <div className="wrapper-cardbox">
                    {
                        deckServices.map((service:any, index:any)=>{
                            const {imgSrc, name} =service;
                            return(
                                <div key={"card-service"+index+name} className="grid-item">
                                    <div className="card-service">
                                            <img src={imgSrc} alt={name} onClick={()=>navigate("/product-catalogs")}/>
                                            <h3>{name}</h3>
                                    </div>
                                </div>
                            )
                        })
                    }
                </div>
            </div>
        </div>
    )
};

export default HomeScreen3;