import "./AppFooter.css";
import globalStates from '../../utils/global';
import { useContext } from "react";
import { iconFooterCall, iconFooterIg, iconFooterMail, iconFooterWa } from "../../assets";
import { useNavigate } from "react-router-dom";
import FloatingIcons from "../FloatingIcon";

const AppFooter = () =>{
    const context = globalStates && globalStates.globalContext;
    const globalContext:any = useContext(context);
    const windowWidthClass = globalContext && globalContext.windowWidthClass;

    const navigate = useNavigate()

    const footerAnchors = [
        {
            imgSrc: iconFooterCall,
            desc: "021 29986863",
            onclickfooter: ()=>null,
        },
        {
            imgSrc: iconFooterWa,
            desc: "+6287825096535",
            onclickfooter:  ()=>openWa('6287825096535')
        },
        {
            imgSrc: iconFooterMail,
            desc: "salesmarketing@naturalhoodspices.com",
            onclickfooter: ()=>handleEmail('salesmarketing@naturalhoodspices.com'),
        },
        {
            imgSrc: iconFooterIg,
            desc: "@naturalhoodspices",
            onclickfooter: ()=>null,
        }
    ]
     const handleEmail = (destination:string) =>{
        const email = destination || 'salesmarketing@naturalhoodspices.com';
        const subject = 'More information about NaturalHoodSpices';
        const body = 'Hi, I want to know more about your products. Thank you.';

        // Create the mailto link
        const mailtoLink = `mailto:${email}?subject=${encodeURIComponent(subject)}&body=${encodeURIComponent(body)}`;

        // Create a temporary anchor element to trigger the mail client
        const tempLink = document.createElement('a');
        tempLink.href = mailtoLink;
        tempLink.click();
    }
    const openLinkHandler = (link:any) =>{
        // console.log("link", link)
        console.log('footer clicked')
        window.open(link, '_blank');
    }

    const openWa = (wa:string)=>{
        const waNumber = wa || '628123456789';
        openLinkHandler(`https://wa.me/${waNumber}?text=Hi,%20I'd%20like%20to%20know%20more%20%20about%20this%20product.%20Thank%20you.%20`)
    }
    return(
        <>
        
        <div className={`${windowWidthClass}-app-footer`}>
            <div className="footer-row-1">
                <h2 onClick={()=>navigate("/")}>NaturalHoodSpices</h2>
            </div>
            <div className="footer-row-2">
                {
                    footerAnchors.map((anchor:any, index:any)=>{
                        const {imgSrc, desc, onclickfooter} = anchor;
                        return(
                            <div key={index+desc} className="footer-icon-group">
                                <img src={imgSrc} alt={desc} onClick={onclickfooter}/>
                                <p onClick={onclickfooter}>{desc}</p>
                            </div>
                        )
                    })
                }
                {/* <div className="footer-icon-group">
                    <img/>
                    <p>021 29986863</p>
                </div>
                <div className="footer-icon-group">
                    <img/>
                    <p>+6283814319841</p>
                </div>
                <div className="footer-icon-group">
                    <img/>
                    <p>adminmarketing@naturalhoodspices.com</p>
                </div>
                <div className="footer-icon-group">
                    <img/>
                    <p>@naturalhoodspices</p>
                </div> */}
            </div>
        </div>
        {/* <div className={`${windowWidthClass}-app-footer-floating-icon`}>
            <img src={iconFloatingWa} alt="green whatsapp icon"/>
        </div> */}
        <FloatingIcons/>
        </>
    )
};

export default AppFooter;