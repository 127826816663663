import "./ContactsScreen1.css";
import globalStates from '../../utils/global';
import { useContext } from "react";
import { AppNavbar } from "../../components";

const ContactsScreen1 = () =>{
    const context = globalStates && globalStates.globalContext;
    const globalContext:any = useContext(context);
    const windowWidthClass = globalContext && globalContext.windowWidthClass;

    return(
        <div className={`${windowWidthClass}-contacts-screen-1`}>
            <AppNavbar/>
            <div className="screen-wrapper">
                <div className="wrapper-tagline">
                    {/* <h1></h1> */}
                    <p>Company Profile</p>
                </div>
                <div className="wrapper-button">
                    {/* <p>request quote</p> */}
                </div>
            </div>
        </div>
    )
};

export default ContactsScreen1;