

import {AdvancedMarker, APIProvider, Map} from '@vis.gl/react-google-maps';
import globalStates from '../../utils/global';
import { useContext } from 'react';
import "./MapComponent2.css"



// const test ={lat: 22.54992, lng: 0};
const mapCoordinates ={lat: -6.134274780567815, lng: 106.8310179529647 };

const API_KEY = process.env.REACT_APP_GOOGLE_MAPS_API_KEY as string;

const MapComponent2 = () => {

        const context = globalStates && globalStates.globalContext;
        const globalContext:any = useContext(context);
        const windowWidthClass = globalContext && globalContext.windowWidthClass;

    return (
        <>
            <div className={`${windowWidthClass}-map-wrapper`}>
                <h2>Map</h2>
                <APIProvider apiKey={API_KEY}>
                    <Map
                        mapId={'natural-hood-spices-location'}
                        style={{width: '100%', height: '80%'}}
                        defaultCenter={mapCoordinates}
                        defaultZoom={14}
                        gestureHandling={'greedy'}
                        disableDefaultUI={true}>
                        
                        {/* <Marker
                            position={mapCoordinates}
                            clickable={false}
                            title={'clickable google.maps.Marker'}
                        /> */}
                        <AdvancedMarker
                            position={mapCoordinates}
                            title={'AdvancedMarker with customized pin.'}>
                            {/* <Pin
                                background={'#22ccff'}
                                borderColor={'#1e89a1'}
                                glyphColor={'#0f677a'}></Pin> */}
                        </AdvancedMarker>
                    </Map>
                </APIProvider>
            </div>
        </>
    )};

export default MapComponent2;