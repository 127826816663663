import { AppFooter } from "../../components";
import { ContactsScreen1, ContactsScreen2 } from "../../containers";
import "./Contacts.css";

const Contacts = () =>{

    return(
        <div className="page-contacts">
            <ContactsScreen1/>
            <ContactsScreen2/>
            <AppFooter/>
        </div>
    )
};

export default Contacts;