import "./AppNavbar.css";
import globalStates from '../../utils/global';
import { useContext, useEffect, useState } from "react";
import { burger, iconProduct } from "../../assets";
import { useNavigate } from "react-router-dom";


const AppNavbar = ({additional}:any) =>{
    const navClass = (additional && additional.navClass) || "";

    const context = globalStates && globalStates.globalContext;
    const globalContext:any = useContext(context);
    const windowWidthClass = globalContext && globalContext.windowWidthClass;
    const defaultLanguage = (globalContext && globalContext.defaultLanguage) || "";
    const setDefaultLanguage = globalContext && globalContext.setDefaultLanguage;

    const [scrolled, setScrolled] = useState(false);
    const [isBurgerCollapsed, setIsBurgerCollapsed] = useState(false);

    const navigate = useNavigate();

    // functions
    const disableBodyRoll = (value:string) =>{
        return document.body.style.overflow = value;
    };

    const handleBurger = () =>{
        setIsBurgerCollapsed(true);
        disableBodyRoll("hidden");
    };

    const handleCollapsed = () =>{
        setIsBurgerCollapsed(false);
         disableBodyRoll("");
    };

    const handleNavigate=(destination:string)=>{
        const path = destination || "/"
        setIsBurgerCollapsed(false);
        disableBodyRoll("");
        navigate(path);
    }

    const handleDefaultLanguage = () =>{
        if (defaultLanguage === "en" ){
            setDefaultLanguage("ina")
        } else {
            setDefaultLanguage("en")
        }
    }

    // console.log(scrolled)
    useEffect(()=>{
        
        const handleScroll = () => {
            const scrolled = window.scrollY > 0;
            setScrolled(scrolled);  
        };
        window.addEventListener('scroll', handleScroll);
        return()=>{
             window.removeEventListener('scroll', handleScroll);
        }
    },[scrolled, isBurgerCollapsed]);

    return(
        <>
        <nav className={`${windowWidthClass}-app-navbar   ${(scrolled ?(windowWidthClass+"-navbar-scrolled") : "")}  ${(scrolled ?"" :(windowWidthClass+"-"+navClass))}`}>
            <div className="anchor-group-1">
                <div>   
                    <img src={iconProduct} alt="natural hood spice company logo" onClick={()=>handleNavigate("/")}/>
                </div>
                
            </div>
            <div className="anchor-group-2">
               
            </div>
          
            <div className="anchor-group-3">

                {
                    windowWidthClass==="w-mob" ?
                    // <p className="burger" onClick={handleBurger}>&#x2261;</p>
                    <>
                    <p onClick={()=>handleDefaultLanguage()}>{defaultLanguage.toUpperCase()}</p>
                    <div className="burger-div" onClick={handleBurger}>
                        <img className="img-burger" src={burger} alt="burger icon" />
                    </div>
                        
                    </>
                    
                    
                    :
                    <>
                        <p onClick={()=>navigate("/")}>Home</p>
                        <p onClick={()=>navigate("/product-catalogs")}>Product Catalogs</p>
                        <p onClick={()=>navigate("/about-us")}>About Us</p>
                        <p onClick={()=>handleDefaultLanguage()}>{defaultLanguage.toUpperCase()}</p>
                    </>
                }

            </div>
        </nav>
        {
            isBurgerCollapsed ? 
            <div className={`burger-collapsed`}>
                <div className="anchor-group-3-collapsed" >
                    <p className="collapsed-close" onClick={handleCollapsed}>&#x00D7;</p>
                    <img src={iconProduct} alt="natural hood spice company logo" onClick={()=>handleNavigate("/")}/>
                    <h4>NaturalHoodSpices</h4>
                    <p onClick={()=>handleNavigate("/")}>Home</p>
                    <p onClick={()=>handleNavigate("/product-catalogs")}>Product Catalogs</p>
                    <p onClick={()=>handleNavigate("/about-us")}>About Us</p>
                </div>
            </div> : null
        }
        
        </>
    )
};

export default AppNavbar;