
import { AppFooter } from "../../components";
import { CatalogsScreen1 } from "../../containers";
import "./Catalogs.css";

const Catalogs = () =>{

    return(
        <div className="">
            <CatalogsScreen1/>
            <AppFooter/>
        </div>
    )
};

export default Catalogs;