import { AppNavbar } from "../../components";
import "./HomeScreen1.css";
import globalStates from '../../utils/global';
import { useContext } from "react";
import { check2 } from "../../assets";

const HomeScreen1 = () =>{
    const context = globalStates && globalStates.globalContext;
    const globalContext:any = useContext(context);
    const windowWidthClass = globalContext && globalContext.windowWidthClass;

    const openLinkHandler = (link:any) =>{
        // console.log("link", link)
        console.log('footer clicked')
        window.open(link, '_blank');
    }

    const openWa = (wa:string)=>{
        const waNumber = wa || '628123456789';
        openLinkHandler(`https://wa.me/${waNumber}?text=Hi,%20I'd%20like%20to%20request%20quote%20%20for%20your%20product.%20Thank%20you.%20`)
    }
    return(
        <div className={`${windowWidthClass}-home-screen-1-tagline`}>
            <AppNavbar/>
            <div className="screen-wrapper">
                <div className="wrapper-tagline">
                    <div className="hashtag">
                        <h2>#GradeNatural </h2>
                        <img src={check2} alt="green checklist"/>
                    </div>
                    
                    <h1>NaturalHoodSpices</h1>
                    <p>Spice With Us, Spices Good</p>
                </div>
                <div className="wrapper-button">
                    <p onClick={()=>openWa('6287825096535')}>Request Quote</p>
                </div>
            </div>
        </div>
    )
};

export default HomeScreen1;