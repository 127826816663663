import "./HomeScreen2.css";
import globalStates from '../../utils/global';
import { useContext, useEffect, useMemo, useState } from "react";

const HomeScreen2 = () =>{
    const context = globalStates && globalStates.globalContext;
    const globalContext:any = useContext(context);
    const windowWidthClass = globalContext && globalContext.windowWidthClass;
    const defaultLanguage = globalContext && globalContext.defaultLanguage;

    const companyDescIna = useMemo(()=>{
        return {
        descTitle:"PT. Sinar Surya Fortuna",
        descDetails: "Sebuah perusahaan Indonesia yang mengkhususkan diri dalam perdagangan rempah-rempah, ekspor, dan impor."
    }
    }, [])

    const companyDescEn = useMemo(()=>{
        return {
        descTitle:"PT. Sinar Surya Fortuna",
        descDetails: "An Indonesian company specializing in spice trade, exports, and imports."
    }
    }, [])

    const coreValuesEn = useMemo(() => {
        return [
        {
            value: "Domestic & International Trades",
            desc: "We facilitate seamless trading activities both domestically and internationally, ensuring premium Indonesian spices reach markets worldwide. Our expertise in global trade allows us to meet the diverse needs of our clients effectively."
        },
        {
        value: "Well Resource & High Quality",
            desc: "Our strength lies in well-established resources and a commitment to high-quality standards. We meticulously select spices to ensure only the best products reach our clients, adhering to stringent quality controls."
        },
        {
            value: "Supporting Local Economy",
            desc: "We support the local economy by empowering Indonesian farmers and suppliers. By fostering sustainable practices and fair trade, we enhance the livelihoods of local communities, ensuring a positive economic impact."
        },
    ]}, [])
    const coreValuesIna = useMemo(() => {
        return [
        {
            value: "Domestik & Internasional",
            desc: "Kami memfasilitasi perdagangan yang lancar di dalam dan luar negeri, memastikan rempah-rempah premium Indonesia mencapai pasar dunia. Keahlian kami memungkinkan kami memenuhi beragam kebutuhan klien dengan efektif."
        },
        {
        value: "Sumber Daya Berkualitas",
            desc: "Kekuatan kami pada sumber daya yang mapan dan komitmen terhadap standar kualitas tinggi. Kami memilih rempah-rempah dengan teliti untuk memastikan hanya produk terbaik sampai ke tangan klien, dengan kontrol kualitas ketat."
        },
        {
            value: "Mendukung Ekonomi Lokal",
            desc: "Kami mendukung ekonomi lokal dengan memberdayakan petani dan pemasok Indonesia. Melalui praktik berkelanjutan dan perdagangan adil, kami meningkatkan kesejahteraan komunitas lokal dan dampak ekonomi positif."
        },

    ]
    }, []);

    const [coreValues, setCoreValues] = useState(coreValuesEn);
    const [companyDesc, setCompanyDesc] = useState(companyDescEn);
    useEffect(()=>{
        if(defaultLanguage==="en"){
            setCompanyDesc(companyDescEn)
            setCoreValues(coreValuesEn)
        } else {
            setCompanyDesc(companyDescIna)
            setCoreValues(coreValuesIna)
        }
    }, [defaultLanguage, coreValuesEn, coreValuesIna, companyDescEn, companyDescIna]);

 
    
    const { descDetails, descTitle} = companyDesc;
    return(
        <div className={`${windowWidthClass}-home-screen-2-tagline`}>
            <div className="screen-wrapper">
                <div className="wrapper-desc-company">
                    {/* <h1>screen2</h1> */}
                    <p className="desc-title">{descTitle}</p>
                    <p className="desc-details">{descDetails}</p>
                </div>
                 <div className="wrapper-core-values">
                    {/* <h1>Core Values</h1> */}
                    <div className="cardbox-core-values">
                        {
                            coreValues.map((core: any, index:any)=>{
                                const {value, desc} = core;
                                return(
                                    <div key={"card-core-value"+index+value} className="grid-item">
                                        <div  className={`card-core-value`}>
                                            <h3>{value}</h3>
                                            <p>{desc}</p>
                                        </div>
                                    </div>
                                )
                            })
                        }
                    </div>
                </div>
            </div>
        </div>
    )
};

export default HomeScreen2;