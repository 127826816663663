import "./ContactsScreen2.css";
import globalStates from '../../utils/global';
import { useContext, useEffect, useMemo, useState } from "react";
import { iconCompany } from "../../assets";
import { MapComponent2 } from "../../components";

const ContactsScreen2 = () =>{
    const context = globalStates && globalStates.globalContext;
    const globalContext:any = useContext(context);
    const windowWidthClass = globalContext && globalContext.windowWidthClass;
    const defaultLanguage = globalContext && globalContext.defaultLanguage;

    const companyProfileEn = useMemo(()=>{
        return {
            title1: "PT. Sinar Surya Fortuna",
            desc1: "PT Sinar Surya Fortuna is a company that focuses on the export and import of spices. Indonesia, as one of the tropical countries, has an abundance of natural resources, including a wide variety of spices that are renowned worldwide. We optimize this natural wealth, becoming one of the trading companies with a solid network and resources. Our goal is to provide high-quality spice products to clients, both domestically and in the international market. With an experienced and competent team, we are committed to continuously improving the standards of our products and services, ensuring client satisfaction and guaranteeing the smooth distribution process.",
            title2: "Vision and Mission",
            desc2: [
                "Our vision at PT Sinar Surya Fortuna is to become a leading company in the spice trade, recognized both in Indonesia and internationally. We aspire to introduce and promote Indonesian spices to all corners of the world, thereby increasing appreciation for Indonesia's natural wealth and cultural heritage.",
                "Our mission encompasses several important aspects. First, we are determined to achieve client satisfaction and trust through high-quality products and excellent services. Second, we are committed to supporting local economic growth by empowering spice farmers and related communities. Third, we actively support government programs aimed at introducing Indonesian spices to the world, in line with the 'Spices up the World' initiative. Through dedication and continuous efforts, we believe we can make a positive contribution to Indonesia's economy and culture, while fulfilling the expectations and needs of our clients."],
            title3: "Address",
            desc3: "WTC Mangga dua LG Floor Blok C No.53, Pademangan, North Jakarta 14420"
        }
    }, []);

    
    const companyProfileIna = useMemo(()=>{
        return{
            title1: "PT. Sinar Surya Fortuna",
            desc1: "PT Sinar Surya Fortuna adalah sebuah perusahaan yang berfokus pada kegiatan ekspor dan impor rempah-rempah. Indonesia, sebagai salah satu negara tropis, memiliki kekayaan sumber daya alam yang sangat melimpah, termasuk di dalamnya berbagai jenis rempah-rempah yang terkenal di seluruh dunia. Kami memanfaatkan kekayaan alam ini dengan optimal, menjadi salah satu perusahaan perdagangan yang memiliki jaringan dan sumber daya yang solid. Tujuan kami adalah menyediakan produk rempah-rempah berkualitas tinggi kepada klien, baik di dalam negeri maupun di pasar internasional. Dengan tim yang berpengalaman dan berkompeten, kami berkomitmen untuk terus meningkatkan standar produk dan layanan kami, memastikan kepuasan klien serta menjamin kelancaran proses distribusi.",
            title2: "Visi dan Misi",
            desc2: ["Visi kami di PT Sinar Surya Fortuna adalah menjadi perusahaan terdepan dalam perdagangan rempah-rempah, yang diakui baik di Indonesia maupun di dunia internasional. Kami bercita-cita untuk memperkenalkan dan mempromosikan rempah-rempah Indonesia ke seluruh penjuru dunia, sehingga dapat meningkatkan apresiasi terhadap kekayaan alam dan budaya Indonesia."," Misi kami mencakup beberapa aspek penting. Pertama, kami bertekad untuk mencapai kepuasan dan kepercayaan klien melalui produk berkualitas tinggi dan layanan prima. Kedua, kami berkomitmen untuk mendukung pertumbuhan ekonomi lokal dengan memberdayakan petani rempah-rempah dan komunitas terkait. Ketiga, kami aktif dalam mendukung program pemerintah yang bertujuan untuk memperkenalkan rempah-rempah Indonesia kepada dunia, sejalan dengan inisiatif 'Spices up the World'. Melalui dedikasi dan upaya yang berkelanjutan, kami yakin dapat memberikan kontribusi positif bagi perekonomian dan kebudayaan Indonesia, sekaligus memenuhi harapan dan kebutuhan klien kami."],
            title3: "Alamat",
            desc3: "WTC Mangga dua LG Floor Blok C No.53, Pademangan, North Jakarta 14420"
        }
    }, []);
    //     const companyProfileEn = useMemo(()=>{
    //     return {
    //         title1: "PT. Sinar Surya Fortuna",
    //         desc1: "Company which engage in trading export/import for spices. Indonesia is one of thropical countries that have so many natural resources and spices are one of them. We are one of trading companies with having well resources and networks for provides high quality products for our domestic and international clients. This is our dedication as PT Sinar Surya Fortuna for Indonesia peoples nor International to achieve clients satisfaction also trust for growing local economic and reintroduce spices from Indonesia in line with government call ” Spices up the World “.",
    //         title2: "Vision and Mission",
    //         desc2: "Company which engage in trading export/import for spices. Indonesia is one of thropical countries that have so many natural resources and spices are one of them. We are one of trading companies with having well resources and networks for provides high quality products for our domestic and international clients. This is our dedication as PT Sinar Surya Fortuna for Indonesia peoples nor International to achieve clients satisfaction also trust for growing local economic and reintroduce spices from Indonesia in line with government call ” Spices up the World “.",
    //         title3: "Address",
    //         desc3: "WTC Mangga dua LG Floor Blok C No.53, Pademangan, North Jakarta 14420"
    //     }
    // }, []);

    
    // const companyProfileIna = useMemo(()=>{
    //     return{
    //         title1: "PT. Sinar Surya Fortuna",
    //         desc1: "Perusahaan yang bergerak di bidang ekspor/impor rempah-rempah. Indonesia adalah salah satu negara tropis yang memiliki banyak sumber daya alam, termasuk rempah-rempah. Kami adalah salah satu perusahaan perdagangan yang memiliki sumber daya dan jaringan yang baik untuk menyediakan produk berkualitas tinggi bagi klien domestik dan internasional. Dedikasi kami sebagai PT Sinar Surya Fortuna adalah untuk masyarakat Indonesia dan internasional untuk mencapai kepuasan dan kepercayaan klien, serta untuk mendukung pertumbuhan ekonomi lokal dan memperkenalkan kembali rempah-rempah dari Indonesia sesuai dengan panggilan pemerintah 'Spices up the World.'",
    //         title2: "Visi dan Misi",
    //         desc2: "Perusahaan yang bergerak di bidang ekspor/impor rempah-rempah. Indonesia adalah salah satu negara tropis yang memiliki banyak sumber daya alam, termasuk rempah-rempah. Kami adalah salah satu perusahaan perdagangan yang memiliki sumber daya dan jaringan yang baik untuk menyediakan produk berkualitas tinggi bagi klien domestik dan internasional. Dedikasi kami sebagai PT Sinar Surya Fortuna adalah untuk masyarakat Indonesia dan internasional untuk mencapai kepuasan dan kepercayaan klien, serta untuk mendukung pertumbuhan ekonomi lokal dan memperkenalkan kembali rempah-rempah dari Indonesia sesuai dengan panggilan pemerintah 'Spices up the World.'",
    //         title3: "Alamat",
    //         desc3: "WTC Mangga dua LG Floor Blok C No.53, Pademangan, North Jakarta 14420"
    //     }
    // }, []);


    const [companyProfile, setCompanyProfile] = useState(companyProfileEn as any);
    useEffect(()=>{
        if(defaultLanguage==="en"){
            setCompanyProfile(companyProfileEn)
        } else {
            setCompanyProfile(companyProfileIna)
        }
    }, [defaultLanguage, companyProfileEn, companyProfileIna]);

 
    const {title1, desc1, title2, desc2, title3, desc3} = companyProfile;
    return(
        <div className={`${windowWidthClass}-contacts-screen-2`}>
            <div className="screen-wrapper">
                <img src={iconCompany} alt="company logo"/>
                <h2>{title1}</h2>
                <p>{desc1}</p>

                <h2>{title2}</h2>
                <p>{desc2[0]}</p>
                <p>{desc2[1]}</p>

                <h2>{title3}</h2>
                <p>{desc3}</p>

                <MapComponent2/>
            </div>
            
        </div>
    )
};

export default ContactsScreen2;