
import './App.css';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { Catalogs, Contacts, Home } from './pages';
import globalStates from './utils/global';
import { useWindowSize } from '@uidotdev/usehooks';
import { useEffect,  useState } from 'react';
import { ScrollToTop } from './components';




function App() {
  const globalContext = globalStates && globalStates.globalContext as any;
  
  const [windowWidthClass, setWindowWidthClass] = useState("w-pc");
  const windowSize = useWindowSize() as {width: any, height: any};
  const windowWidth = windowSize && windowSize.width;

  const [defaultLanguage, setDefaultLanguage] = useState("en");

  useEffect(
    ()=>{
      if(+windowWidth < 650){
      setWindowWidthClass("w-mob");
    }

    else if (+windowWidth >= 650 && +windowWidth < 1025) {
      setWindowWidthClass("w-tab");
    }
    
    else {
      setWindowWidthClass("w-pc");
    }
    }, [windowSize, windowWidth]
  )
  return (
    <>
    
    <globalContext.Provider value={{windowSize, windowWidthClass, defaultLanguage, setDefaultLanguage}}>
      <BrowserRouter>
        <ScrollToTop/>
        <Routes>
          <Route path="/" element={<Home/>}/>
          <Route path="/product-catalogs" element={<Catalogs/>}/>
          <Route path="/about-us" element={<Contacts/>}/>
        </Routes>
      </BrowserRouter>
    </globalContext.Provider>
    </>
  );
}

export default App;
